import React from "react"
import { graphql, Link } from "gatsby"

import ContactForm from "../components/Global/ContactForm"
import Breadcrumb from "../components/Global/Breadcrumb"
import ContactInformation from "../components/Contact/ContactInformation"




import Layout from '../components/layout'


function ContactTemplate(props) {
    let data = props.data;

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}${props.pageContext.currentPage}`
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>
                    {/* <section>
                        <Introduction backImg={data.allWpPage.nodes[0].contactPageAcf.introductionBackgroundImage} title={data.allWpPage.nodes[0].contactPageAcf.introductionHeader} />
                    </section> */}
                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>
                    <div className="flex flex-wrap container m-auto">
                        <div className="w-full md:w-1/3">
                            <ContactInformation data={data.allWpTemplate.nodes[0].contactInformationTemplate} language={props.pageContext.language} />
                        </div>
                        <div className="w-full md:w-2/3">
                            <ContactForm
                                form={data.allWpTemplate.nodes[0].contactInformationTemplate.form}
                                responses={data.allWpTemplate.nodes[0].contactInformationTemplate.responseMessages}
                                endPoint={"contactEmail"}
                                language={props.pageContext.language}
                                subjectRequired={true}
                                messageRequired={true}
                            />
                        </div>
                    </div>

                </div>
            </Layout>
        </div>
    )
}

export default ContactTemplate


export const pageQuery = graphql`query GET_CONTACT_PAGE($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
        
       }

    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`

