import React, { useState } from 'react'
import "./ContactInformation.css"

import Marker from "../../images/placeholder.svg";
import Email from "../../images/email.svg";
import Tele from "../../images/phone-call.svg";
import cutLogo from "../../images/cut-logo.png";
import cutLogoEN from "../../images/LOGO-DIKTYO-MAIN-ΕΝ.png"


function ContactInformation(props) {
    const data = props.data;

    console.log(data)

    return (
        <div className="py-12 ci-tab px-8">
            <div className='pb-4'>
                {
                    props.language === "EN"
                    ?
                    <img src={cutLogoEN} />
                    :
                    <img src={cutLogo} />
                }
            </div>
            <div className="pb-4 w-full text-left justify-start pt-4" >
                <div className="table-cell" style={{ width: "32px" }}>
                    <img src={Marker} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={data.address.addressGoogleLink} className="ci-links table-cell align-middle">{data.address.addressLabel}</a>
            </div>
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell">
                    <img src={Tele} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={`tel:${data.telephoneNumber}`} className="ci-links table-cell align-middle">{data.telephoneNumber}</a>
            </div>
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell">
                    <img src={Email} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={`mailto:${data.supportEmail}`} className="ci-links table-cell align-middle">{data.supportEmail}</a>
            </div>

            {
                props.language === "EN"
                    ?
                    <div className="pb-4 w-full text-left justify-start pt-4 flex-wrap" >
                        <h4 className='font-semibold'>General Manager:</h4>
                        <p className="ci-links align-middle w-full block pb-4" style={{ paddingLeft: "0px" }}>Despoina Kranidou <a style={{ color: "#0073aa" }} href='mailto:info@dkaa.gr'>info@dkaa.gr</a></p>
                        <h4 className='font-semibold'>Marketing & Communications Manager:</h4>
                        <p className="ci-links align-middle w-full block" style={{ paddingLeft: "0px" }}>Anastasios Papadopoulos <a style={{ color: "#0073aa" }} href='mailto:marketing@dkaa.gr'>marketing@dkaa.gr</a></p>
                    </div>
                    :
                    <div className="pb-4 w-full text-left justify-start pt-4 flex-wrap" >
                        <h4 className='font-semibold'>Υπεύθυνη Δικτύου:</h4>
                        <p className="ci-links align-middle w-full block pb-4" style={{ paddingLeft: "0px" }}>Δέσποινα Κρανίδου <a style={{ color: "#0073aa" }} href='mailto:info@dkaa.gr'>info@dkaa.gr</a> </p>
                        <h4 className='font-semibold'>Υπεύθυνος Μάρκετινγκ & Επικοινωνιών: </h4>
                        <p className="ci-links align-middle w-full block" style={{ paddingLeft: "0px" }}>Αναστάσιος Παπαδόπουλος  <a style={{ color: "#0073aa" }} href='mailto:marketing@dkaa.gr'>marketing@dkaa.gr</a></p>
                    </div>
            }


        </div>
    )
}

export default ContactInformation;