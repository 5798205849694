import React, { useState, useRef, useEffect } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Formsy from 'formsy-react';
import axios from "axios"

import FormsyInput from "./../Formsy/formsyInput"
import FormsyTextArea from "./../Formsy/FormsyTextArea"

import "./ContactForm.css";


function ContactForm(props) {
    const data = props;

    console.log(data)

    const formRef = useRef(null);


    const [canSubmit, setCanSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showErrorCheckbox, setShowErrorCheckbox] = useState(false);
    const [hasGdpr, setHasGdpr] = useState(false)

    useEffect(() => {
        if (canSubmit) {
            setShowErrorMessage(false)
        }
    }, [canSubmit])


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        console.log("mesa")
        if (hasGdpr) {
            setLoading(true)

            let type = "";

            if (model.fullName) {
                let payload = {
                    ...model,
                    language: props.language,
                }

                axios.post(`https://admin.dkaa.gr/wp-json/myplugin/v1/websiteContactEmail`, payload)
                    .then(function (response) {
                        // handle success
                        setEmailFailed(false)
                        setLoading(false)
                        setEmailSent(true)
                        setEmailResponse(response.data)

                    })
                    .catch(function (error) {
                        // handle error
                        setLoading(false)
                        setEmailFailed(true)
                        setEmailResponse(data.responses.failed)
                    })
            }
        } else {
            setLoading(false)
            setEmailFailed(true)
            setEmailResponse("Αποδεχτείτε τους Όρους χρήσης και την Πολιτική Προστασίας")
        }
    }


    return (
        <div className={`${emailSent ? "cf-container" : "cf-container "} px-4 py-12 md:px-0`}>
            <div className={`m-auto ${emailSent ? "max-w-none px-8 " : "max-w-xl px-4 "}relative`} >
                {loading && (
                    <div className="loader-container">
                        <div className="loader">Loading...</div>
                    </div>
                )}


                {!emailSent
                    ?
                    <Formsy
                        onValidSubmit={submit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        className={loading ? "opacity-25" : "opacity-100"}
                        ref={formRef}
                    >
                        <div>
                            <p className="text-md" style={{ color: "#222", fontSize: "14px", letterSpacing: "2px" }}>
                                {data.form.subTitle}
                            </p>
                        </div>
                        <h2 className="text-4xl">
                            {data.form.title}
                        </h2>

                        <div className="mt-12">

                            <div className="flex flex-wrap">
                                <FormsyInput
                                    name="fullName"
                                    placeholder={data.form.fullName}
                                    classes="mt-4 w-1/2 pr-4"
                                    required
                                />
                                <FormsyInput
                                    name="email"
                                    validations={"isEmail"}
                                    validationErrors={{
                                        isEmail: data.form.email.errorMessage,
                                    }}
                                    placeholder={data.form.email.label}
                                    classes="mt-4 w-1/2 pl-4"
                                    required
                                />
                            </div>
                            <div className="flex flex-wrap">
                                <FormsyInput
                                    name="telephone"
                                    validations={"minLength:10"}
                                    type="number"
                                    validationErrors={{
                                        minLength: data.form.telephone.errorMessage,
                                    }}
                                    placeholder={`${data.form.telephone.label} ${data.teleRequired ? "*" : ""}`}
                                    classes="mt-4 w-1/2 pr-4"
                                    required={data.teleRequired ? true : false}
                                />

                                <FormsyInput
                                    name="subject"
                                    placeholder={data.form.subject}
                                    classes="mt-4 w-1/2 pl-4"
                                    defaultValue={data.subject}
                                    required
                                />
                            </div>


                            <FormsyTextArea
                                name="message"
                                validations={"minLength: 9"}
                                validationErrors={{
                                    minLength: data.form.message.errorMessage,
                                }}
                                placeholder={data.form.message.label}
                                classes="mt-4"
                                required
                            />

                        </div>

                        {props.language === "EL"
                            ?
                            <div className='pb-2'>
                                <span>
                                    <input type="checkbox" checked={hasGdpr} onClick={() => setHasGdpr(!hasGdpr)} />
                                </span>
                                <span className='pl-2'>
                                    Αποδέχομαι τους  
                                    <a href={"https://dkaa.gr/oroi-xrisis/"} target='_blank' className='pl-2' style={{color:"#0056b3"}}>Όρους χρήσης και την</a>  
                                    <a href={"https://dkaa.gr/prosopika-dedomena/"} target='_blank' className='pl-2' style={{color:"#0056b3"}}>Πολιτική Προστασίας Προσωπικών Δεδομένων</a>
                                </span>
                            </div>
                            :
                            <div className='pb-2'>
                                <span>
                                    <input type="checkbox" checked={hasGdpr} onClick={() => setHasGdpr(!hasGdpr)} />
                                </span>
                                <span className='pl-2'>
                                    I accept the 
                                    <a href={"https://dkaa.gr/en/oroi-xrisis-en/"} target='_blank' className='pl-2' style={{color:"#0056b3"}}>Terms</a> and  
                                    <a href={"https://dkaa.gr/en/prosopika-dedomena-en/"} target='_blank' className='pl-2' style={{color:"#0056b3"}}>Privacy Policy</a>
                                </span>
                            </div>

                        }

                        {emailFailed && (
                            <p className='pb-4' style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                        )}

                        <button type='submit' className={"main-button-blue mt-4 md:mt-0"} style={{ width: "auto", height: "auto" }}>
                            {data.form.sendButton}
                        </button>

                        {showErrorMessage && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                        )}
                        {showErrorCheckbox && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Παρακαλoύμε αποδεχτείτε τους όρους χρήσης</p>
                        )}
                    </Formsy>
                    :
                    <div >
                        <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                    </div>
                }

            </div>
        </div>
    )
}

export default ContactForm;